"use client";

import { useSearchParams } from "next/navigation";
import { useEffect, useRef } from "react";
import { useCartData } from "./useCartData";

interface CartRefCodeProps {}

export function CartRefCode(props: CartRefCodeProps) {
  const hasCalledRef = useRef(false);
  const searchParams = useSearchParams();
  const refCodeQuery = searchParams.get("ref");
  const {
    cart,
    setCartRefCode: { setCartRefCode },
  } = useCartData();

  const cartRefCode = cart?.refCode;

  useEffect(() => {
    if (
      !hasCalledRef.current &&
      cart?.id &&
      refCodeQuery &&
      cartRefCode != refCodeQuery
    ) {
      hasCalledRef.current = true;
      setCartRefCode({ refCode: refCodeQuery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartRefCode, refCodeQuery, cart?.id]);

  return null;
}

/**
 this is used only with GoAffPro app, whenever we have a ref code on the query params
 we will send it to the backend to track the cart progress 
 */
